import { loadManifest } from '@angular-architects/module-federation';

import { environment } from './environments/environment';

const manifestPath = environment.production ? './assets/mfe.manifest.json' : './assets/mfe.manifest.develop.json';

loadManifest(manifestPath, true)
    .catch((err) => console.error(err))
    .then((_) => import('./bootstrap'))
    .catch((err) => console.error(err));
// import('./bootstrap').catch((err) => console.error(err));
